/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
class Emitter {
  // El constructor del programa
  constructor() {
    this.listeners = new Map();
  }

  // Es para agregar una nueva lista
  addListener(label, callbacked) {
    if (typeof callbacked === 'function') {
      this.listeners.has(label) || this.listeners.set(label, []);
      this.listeners.get(label).push({ callback: callbacked });
      return true;
    }
    return false;
  }

  // Es para eliminar un data de una lista
  removeListener(label, callback, vm) {
    const listeners = this.listeners.get(label);
    let index;

    if (listeners && listeners.length) {
      index = listeners.reduce((i, listener, index) => {
        if (typeof listener.callback === 'function' && listener.callback === callback && listener.vm === vm) {
          i = index; // eslint-disable-line no-param-reassign
        }
        return i;
      }, -1);

      if (index > -1) {
        listeners.splice(index, 1);
        this.listeners.set(label, listeners);
        return true;
      }
    }
    return false;
  }

  // Se crea una función para guardar los datos en una lista
  emit(label, args) {
    const listeners = this.listeners.get(label);
    if (listeners && listeners.length) {
      listeners.forEach((listener) => {
        listener.callback(args);
      });
      return true;
    }
    return false;
  }
}

export default new Emitter();

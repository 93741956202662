// Elementos
const state = {
  devicesItems: {},
  deviceSelected: null,
  devicesSelected: [],
};
// Llamar elementos de este módulo a otros componentes
const getters = {
  devicesItems: (state) => state.devicesItems,
  deviceSelected: (state) => state.deviceSelected,
  devicesSelected: (state) => state.devicesSelected,
};
// Comportamiento
const mutations = {
  // Seleccione el dispositivo
  deviceSelect(state, payload) {
    state.deviceSelected = payload;
    state.devicesSelected = state.deviceSelected ? [state.deviceSelected] : [];
  },
  // Seleccionar más dispositivo
  devicesSelect(state, payload) {
    state.devicesSelected = payload;
    [state.deviceSelected] = state.devicesSelected;
  },
  // Eliminar dispositivo
  devicesRemove(state, payload) {
    delete state.devicesItems[payload];
  },
  // Fecha de actualización de los dispositivos
  devicesUpdate(state, payload) {
    payload.forEach((item) => { state.devicesItems[item.id] = item; });
  },
  // Actualizar todos los dispositivos de la lista
  devicesRefresh(state, payload) {
    state.devicesItems = {};
    payload.forEach((item) => { state.devicesItems[item.id] = item; });
  },
};

export default { state, getters, mutations };

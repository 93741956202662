// Elementos
const state = {
  routeChargeToCharge: null,
};
// Llamar elementos de este módulo a otros componentes
const getters = {
  routeChargeToCharge: (state) => state.routeChargeToCharge,
};
// Comportamiento
const mutations = {
  // Actualiza rute de carga a carga
  routeChargeToChargeUpdate(state, payload) {
    state.routeChargeToCharge = payload;
  },
};

export default { state, getters, mutations };

import semver from 'semver';
import Connector from './Connection';

/**
 * Install plugin
 * @param app
 * @param axios
 */
/* eslint-disable */
const connection = (app, server) => {
  if (connection.installed) {
    return;
  }
  // Verifica la conexión con el servidor
  if (!server) {
    console.error('SERVER is required');
    return;
  }
  connection.installed = true;
  // Verifica la version del servidor
  const connector = new Connector(server, app);
  if (semver.lt(app.version, '3.0.0')) {
    Object.defineProperties(app.prototype, {
      // Se crean las funciones para conectares con las funciones para las solicitudes del servidor
      connection: {
        get: function get() {
          return connector;
        },
      },
      $connection: {
        get: function get() {
          return connector;
        },
      },

    });
  } else {
    // Si por alguna razón la escribes mal se van a usar las configuraciones que ya se tienes guardades en la aplicación del servidor
    app.config.globalProperties.connection = connector;
    app.config.globalProperties.$connection = connector;
  }
  // Se crea la conexión para llamar el programa usado los dos tipos de funciones
  app.connection = connector;
  app.$connection = connector;
}

export default connection;

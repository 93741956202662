import { watchEffect } from 'vue';
// Actualizar valor en el almacenamiento local
export const saveLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
// Eliminar valor en el almacenamiento local
export const deleteLocalStorage = (key) => window.localStorage.removeItem(key);
// Leer valor o crear valor en el almacenamiento local
export default (key, defaultValue) => {
  const value = () => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue ? JSON.parse(stickyValue) : defaultValue;
  };
  watchEffect(() => {
    if (value() !== defaultValue) {
      saveLocalStorage(key, value());
    } else {
      window.localStorage.removeItem(key);
    }
  });

  return [value()];
};

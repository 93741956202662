import axios from 'axios';
import Emitter from './Emitter';

/* eslint-disable */
// Se conecta a la dirección del servidor y se guarda la autentificación
const connector = function (server, vue) {
  this.server = server;
  this.vue = vue;
  this.vm = false;
  this.ws = null;
  this.listeners = { open: [], message: [], close: [] };
  this.axios = axios.create({
    baseURL: this.server,
    withCredentials: true,
    credentials: 'include',
    validateStatus: (status) => status < 400, // Resolve only if the status code is less than 500
  });

  vue.mixin({
    created: () => {
      connector.vm = this;
    },
  });
  console.log(`Instance of ${server}`);
};
// Conexion para ver si sirve el api
connector.prototype.serve = async function () {
  const server = await this.axios.get('/api/server');
  return server;
}
// Conexión con el web socket
connector.prototype.socket = function () {
  // Change portocol page
  const wsServer = this.server.replace('http://', 'ws://').replace('https://', 'wss://');
  // Connect socket
  this.ws = new WebSocket(`${wsServer}/api/socket`);
  // errores del web socket
  this.ws.onerror = async () => {
    await Emitter.emit('error');
  };
  // conexión al web socket
  this.ws.onopen = async function (event) {
    await Emitter.emit('open', event);
  };
  // cierra el web socket
  this.ws.onclose = async function (event) {
    await Emitter.emit('close', event);
  };
  // resive los mensajes del web socket
  this.ws.onmessage = async function (event) {
    const data = JSON.parse(event.data);
    await Emitter.emit('message', data);
  };
};
// Función para ver los datos de todos los mesajes del websocket
connector.prototype.on = function (label, fnc) {
  Emitter.addListener(label, fnc);
};
// Conexión para la autentificación del usuario ya registrado
connector.prototype.login = async function (email, password) {
  const params = new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`);
  const result = await this.axios.post('/api/session', params);
  return result;
};
// Solicitud de unidades asociadas con el usuario
connector.prototype.geofences = async function () {
  const result = await this.axios.get('/geofences');
  return result;
};
// Solicitud de unidades asociadas con el usuario
connector.prototype.devices = async function () {
  const result = await this.axios.get('/api/devices');
  return result;
};
// Solicitud de grupos asociadas con el usuario
connector.prototype.groups = async function () {
  const result = await this.axios.get('/api/groups');
  return result;
};
// Solicitud de posiciones de las unidades asociadas con el usuario
connector.prototype.positions = async function () {
  const result = await this.axios.get('/api/positions');
  return result;
};
// Solicitud del historial de posiciones de las unidades asociadas con el usuario
connector.prototype.positionsHistory = async function (id, from, to) {
  const result = await this.axios.get(`/api/positions?from=${from}&to=${to}&daily=false&deviceId=${id}`);
  return result;
};
// solicitud para el los datos de los viajes del reporte
connector.prototype.loadTrips = async function (id, from, to) {
  const result = await this.axios.get(`/api/reports/trips?_dc=1653413297957&deviceId=${id}&type=allEvents&from=${from}&to=${to}&daily=false&page=1&start=0&limit=25`);
  return result;
};
// Se solicita para mostrar en el mapa la ruta que hizo en un viaje
connector.prototype.loadRoute = async function (id, from, to) {
  const result = await this.axios.get(`/api/reports/route?_dc=1653413297957&deviceId=${id}&type=allEvents&from=${from}&to=${to}&daily=false&page=1&start=0&limit=25`);
  return result;
};
// Inicio de sesión por el token
connector.prototype.loginToken = async function (token) {
  return await this.axios.get(`/api/session?token${encodeURIComponent(token)}`);
}
// Generador de token
connector.prototype.generateToken = async function (expiration) {
  const params = new URLSearchParams(`expiration=${expiration}`);
  return await this.axios.post('/api/session/token', params);
}

export default connector;

<template>
  <server-provider>
    <template v-slot>
      <router-view />
      <div v-if="initialized">
        <socket-controller />
      </div>
    </template>
  </server-provider>
</template>

<script setup>
import {
  ref, watch, onBeforeMount, defineAsyncComponent,
} from 'vue';
import moment from 'moment';
import 'moment/min/locales.min';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import preloadImages from '@/components/map/function/core/preload-image';

// Datas uses
const $q = useQuasar();
const store = useStore();
// const element = document.documentElement;
const initialized = ref(store.getters.user);
// Componentes que usamos en esta página
const ServerProvider = defineAsyncComponent(() => import('@/components/server/ServerProvider.vue'));
const SocketController = defineAsyncComponent(() => import('@/common/server/socket-controller'));
// Carga antes de montar el projecto
onBeforeMount(() => {
  preloadImages();
  moment.locale(['es']);
});
// Carga el estilo del tema al Framework
const setThemeFramework = (result) => {
  $q.dark.set(result);
  store.commit('updateDarkTheme', result);
};
// Función de cambio de tema en la computadora mientras una página
matchMedia('(prefers-color-scheme: dark').addEventListener('change', (e) => {
  setThemeFramework(e.matches);
});
// Convertir si es válido el uso de ese tema
const isDark = (theme) => {
  const darkQuery = matchMedia('(prefers-color-scheme: dark');
  let result;
  if (!theme) {
    result = darkQuery.matches;
  } else {
    result = theme;
  }
  setThemeFramework(result);
  return result;
};
isDark(store.getters.darkTheme);
// Detecta si has iniciado sesión para conectarte al socket
watch(() => store.getters.user, (u) => {
  initialized.value = u;
});
</script>

<style>
.q-table__top {
  /* height of all previous header rows */
  background: transparent;
}

.my-style-table thead tr th {
  position: sticky;
  z-index: 1;
}

.my-style-table thead tr:first-child th {
  top: 0;
  color: white;
  text-align: center;
  background-color: #6B7280;
}

.my-style-table.q-table--loading thead tr:last-child th {
  /* height of all previous header rows */
  top: 48px;
}

.my-style-table tbody {
  /* height of all previous header rows */
  scroll-margin-top: 48px;
}

.my-style-table-row tbody tr {
  color: black;
  background-color: white;
  border-color: #000;
  /* style: { borderRight: '1px solid #000' } */
}

.my-style-table-row tbody tr:hover {
  background-color: rgba(250, 250, 250, 0.6)
}

.my-style-table tbody tr.selected {
  background-color: #336699;
  color: white;
}

.my-style-table tbody tr.selected:hover {
  background-color: #2d5986;
}
.q-select__dropdown-icon {
  color: #336699;
}
.q-field__native {
  max-height: 150px;
  overflow: auto;
}
</style>

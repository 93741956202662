// Craga el tamaño del las imagenes para el mapa
export const loadImg = (url) => new Promise((imgLoaded) => {
  const image = new Image();
  image.onload = () => imgLoaded(image);
  image.src = url;
});
// Crea los marcdores para el mapa
export const makeIcon = (background) => {
  const canvas = document.createElement('canvas');
  canvas.width = background.width * devicePixelRatio;
  canvas.height = background.height * devicePixelRatio;
  canvas.style.width = `${background.width}px`;
  canvas.style.height = `${background.height}px`;
  const context = canvas.getContext('2d');
  context.drawImage(background, 0, 0, canvas.width, canvas.height);
  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const prepareIcon = (icon, color) => {
  const canvas = document.createElement('canvas');
  canvas.width = icon.width * devicePixelRatio;
  canvas.height = icon.height * devicePixelRatio;
  canvas.style.width = `${icon.width}px`;
  canvas.style.height = `${icon.height}px`;
  const context = canvas.getContext('2d');
  context.save();
  context.shadowColor = 'black';
  context.shadowBlur = '15';
  context.fillStyle = color;
  context.globalAlpha = 1;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.globalCompositeOperation = 'destination-atop';
  context.globalAlpha = 1;
  context.drawImage(icon, 0, 0, canvas.width, canvas.height);
  context.restore();
  return context.getImageData(0, 0, canvas.width, canvas.height);
};

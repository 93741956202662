import busDefault from '@/assets/images/map/bus.svg';
import busSelect from '@/assets/images/map/bus2.svg';
import arrowSvg from '@/assets/images/map/arrow.svg';
import backgroundSvg from '@/assets/images/map/background.svg';
import { getStatusColorRoute } from '@/common/utils/formatter';
import { loadImg, makeIcon, prepareIcon } from './util';

// Imagenes
export const mapIcons = {
  default: busDefault,
  select: busSelect,
  background: backgroundSvg,
};
export const mapRouteIcons = {
  arrow: arrowSvg,
};
// Llaves de cada imagen para llamarlo desde otro componente
// eslint-disable-next-line
export const mapIconsKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');
// eslint-disable-next-line
export const mapRouteIconsKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'arrow');
// Guardado de imagenes para precarga
export const mapImages = {};
// Cargar imagenes
export default async () => {
  mapImages.default = await makeIcon(await loadImg(mapIcons.default));
  mapImages.select = await makeIcon(await loadImg(mapIcons.select));
  mapImages.background = await makeIcon(await loadImg(mapIcons.background));
  await Promise.all(Object.keys(mapRouteIcons).map(async (category) => {
    const result = [];
    ['start', 'finish', 'route'].forEach((color) => {
      result.push(loadImg(mapRouteIcons[category]).then((icon) => {
        mapImages[`${category}-${color}`] = prepareIcon(icon, getStatusColorRoute(color));
      }));
    });
  }));
};

// Elemento
const state = {
  devicesFuel: [],
};
// Llamar elementos de este módulo a otros componentes
const getters = {
  devicesFuel: (state) => state.devicesFuel,
};
// Comportamiento
const mutations = {
  // Agrega combustible de la unidad
  addFuel(state, payload) {
    state.devicesFuel = payload;
  },
  // Agrega a la lista combustible de la unidad
  pushFuel(state, payload) {
    state.devicesFuel.push(payload);
  },
  // Actualiza combustible de la unidad
  updateFuel(state, payload) {
    const index = state.devicesFuel.findIndex((fuel) => fuel.deviceid === payload?.deviceid);
    if (index < 0) state.devicesFuel.push(payload);
    else state.devicesFuel[index] = payload;
  },
};

export default { state, getters, mutations };

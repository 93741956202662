// Elementos
const state = {
  groupsItems: {},
  groupsSelected: null,
  groupesSelected: [],
};
// Llamar elementos de este módulo a otros componentes
const getters = {
  groupsItems: (state) => state.groupsItems,
  groupsSelected: (state) => state.groupsSelected,
  groupesSelected: (state) => state.groupesSelected,
};
// Comportamiento
const mutations = {
// Seleccione el dispositivo
  groupSelect(state, payload) {
    state.groupsSelected = payload;
    state.groupesSelected = state.groupsSelected ? [state.groupsSelected] : [];
  },
  // Seleccionar más dispositivo
  groupsSelect(state, payload) {
    state.groupesSelected = payload;
    state.groupsSelected = state.groupesSelected;
  },

  // Eliminar dispositivo
  groupesRemove(state, payload) {
    delete state.groupsItems[payload];
  },
  // Fecha de actualización de los dispositivos
  groupesUpdate(state, payload) {
    payload.forEach((item) => {
      state.groupsItems[item.id] = item;
    });
  },
  // Actualizar todos los dispositivos de la lista
  groupsRefresh(state, payload) {
    state.groupsItems = {};
    payload.forEach((item) => {
      state.groupsItems[item.id] = item;
    });
  },
};
export default { state, getters, mutations };

import { createRouter, createWebHistory } from 'vue-router';
import useLocalStorage from '@/common/utils/localStorage';
import store from '../store';

function lazyLoadViews(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyLoadViews('main/MainView'),
  },
  {
    path: '/route',
    name: 'route',
    component: lazyLoadViews('main/RouteView'),
  },
  {
    name: 'login',
    path: '/login',
    // props: true,
    component: lazyLoadViews('accounts/LoginView'),
  },
  {
    path: '/:catchAll(.*)',
    component: lazyLoadViews('errors/ErrorNotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// Redirige vista si no se inicio sesión
router.beforeEach(async (to, from, next) => {
  const initialized = store.getters.user;
  if (!initialized) {
    try {
      const [user] = useLocalStorage('auth');
      if (user) {
        store.commit('updateUser', user);
        next();
      } else if (!user) {
        if (to.name === 'login' || to.name === 'register' || to.name === 'resetpassword') next();
        else next({ name: 'login' });
      }
    } catch (error) {
      throw Error('error');
    }
  } else {
    next();
  }
});

export default router;

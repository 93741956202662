import { createStore } from 'vuex';
import session from './modules/session';
import devices from './modules/devices';
import fuel from './modules/fuel';
import route from './modules/route';
import groups from './modules/groups';

export default createStore({
  modules: {
    devices, session, fuel, route, groups,
  },
});

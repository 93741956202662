// Validador para convertir velocidad
const speedConverter = (unit) => {
  switch (unit) {
    case 'kmh':
      return 1.852;
    case 'mph':
      return 1.15078;
    case 'kn':
    default:
      return 1;
  }
};
// Validador para tipos velocidad
export const speedUnitString = (unit) => {
  switch (unit) {
    case 'kmh':
      return 'km/h';
    case 'mph':
      return 'mph';
    case 'kn':
    default:
      return 'Nudos';
  }
};
// Fórmula para conseguir velocidad desde alguna opción
export const speedFromKnots = (value, unit) => value * speedConverter(unit);
// Fórmula para conseguir velocidad a alguna opción
export const speedToKnots = (value, unit) => value / speedConverter(unit);
// Validador para convertir distancia
const distanceConverter = (unit) => {
  switch (unit) {
    case 'mi':
      return 0.000621371;
    case 'nmi':
      return 0.000539957;
    case 'km':
    default:
      return 0.001;
  }
};
// Validador para tipos distancia
export const distanceUnitString = (unit) => {
  switch (unit) {
    case 'mi':
      return 'MI';
    case 'nmi':
      return 'nmi';
    case 'km':
    default:
      return 'Km';
  }
};
// Fórmula para conseguir distancia desde alguna opción
export const distanceFromMeters = (value, unit) => value * distanceConverter(unit);
// Fórmula para conseguir distancia a alguna opción
export const distanceToMeters = (value, unit) => value / distanceConverter(unit);
// Validador para convertir altitud
const altitudeConverter = (unit) => {
  switch (unit) {
    case 'ft':
      return 3.28084;
    case 'm':
    default:
      return 1;
  }
};
// Validador para tipos altitud
export const altitudeUnitString = (unit) => {
  switch (unit) {
    case 'ft':
      return 'ft';
    case 'm':
    default:
      return 'm';
  }
};
// Fórmula para conseguir altitud desde alguna opción
export const altitudeFromMeters = (value, unit) => value * altitudeConverter(unit);
// Fórmula para conseguir altitud a alguna opción
export const altitudeToMeters = (value, unit) => value / altitudeConverter(unit);
// Validador para convertir volumen
const volumeConverter = (unit) => {
  switch (unit) {
    case 'impGal':
      return 4.546;
    case 'usGal':
      return 3.785;
    case 'ltr':
    default:
      return 1;
  }
};
// Validador para tipos volumen
export const volumeUnitString = (unit) => {
  switch (unit) {
    case 'impGal':
      return 'gal';
    case 'usGal':
      return 'gal';
    case 'ltr':
    default:
      return 'L';
  }
};
// Fórmula para conseguir volumen desde alguna opción
export const volumeFromLiters = (value, unit) => value / volumeConverter(unit);
// Fórmula para conseguir volumen a alguna opción
export const volumeToLiters = (value, unit) => value * volumeConverter(unit);

import 'quasar/dist/quasar.css';
import lang from 'quasar/lang/es.js';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/mdi-v6/mdi-v6.css';
import Notify from 'quasar/src/plugins/Notify.js';import Dark from 'quasar/src/plugins/Dark.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    Dark,
    brand: {
      primary: '#262666',
      secondary: '#336699',
      accent: '#F49C32',
      dark: '#374151',
      // dark: '#1d1d1d',
      'dark-page': '#121212',
      positive: '#21BA45',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#F2C037',
    },
  },
  plugins: {
    Notify,
  },
  lang,
};

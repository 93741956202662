import moment from 'moment';
import {
  distanceFromMeters, distanceUnitString, speedFromKnots, speedUnitString, volumeFromLiters, volumeUnitString,
} from './converter';

// Formato para fecha y hora
export const formatTime = (value, format, hours12) => {
  if (value) {
    const m = moment(value);
    switch (format) {
      case 'date':
        return m.format('YYYY-MM-DD');
      case 'time':
        return m.format(hours12 ? 'hh:mm:ss A' : 'HH:mm:ss');
      case 'minutes':
        return m.format(hours12 ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm');
      default:
        return m.format(hours12 ? 'YYYY-MM-DD hh:mm:ss A' : 'YYYY-MM-DD HH:mm:ss');
    }
  }
  return '';
};
// Formato para fecha y hora del dia de hoy
export const formatTimeNow = (format, hours12) => {
  const m = moment();
  switch (format) {
    case 'date':
      return m.format('YYYY-MM-DD');
    case 'time':
      return m.format(hours12 ? 'hh:mm:ss A' : 'HH:mm:ss');
    case 'minutes':
      return m.format(hours12 ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm');
    default:
      return m.format(hours12 ? 'YYYY-MM-DD hh:mm:ss A' : 'YYYY-MM-DD HH:mm:ss');
  }
};
// Formato para convertir booleand
export const formatBoolean = (value) => (value ? 'Si' : 'No');
// Formato para números
export const formatNumber = (value, precision = 1) => Number(value.toFixed(precision));
// Formato para distancia combustible
export const fromatDistanceFuel = (value, unit) => `${value.toFixed(2)} ${distanceUnitString(unit)}`;
// Formato para distancia
export const formatDistance = (value, unit) => `${distanceFromMeters(value, unit).toFixed(2)} ${distanceUnitString(unit)}`;
// Formato para velocidad
export const formatSpeed = (value, unit) => `${speedFromKnots(value, unit).toFixed(2)} ${speedUnitString(unit)}`;
// Formato para volumen
export const formatVolume = (value, unit) => `${volumeFromLiters(value, unit).toFixed(2)} ${volumeUnitString(unit)}`;
// Formato para estato
export const formatStatus = (status) => {
  switch (status) {
    case 'online':
      return 'En línea';
    case 'offline':
      return 'Fuera de línea';
    case 'unknown':
    default:
      return 'Desconocido';
  }
};
// Formato de color de icons de ruta
export const getStatusColorRoute = (status) => {
  switch (status) {
    case 'finish':
      return '#ff0000';
    case 'start':
      return '#004225';
    case 'route':
    default:
      return '#eeb14c';
  }
};

// Formatear número a una cadena con separador de miles y decimales
export const formatCurrencyNumber = (value) => {
  const formattedValue = Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedValue;
};

// Formatear cadena de dinero con símbolo de moneda y separador de miles y decimales
export const formatCurrency = (value, currencySymbol) => {
  const formattedValue = `${currencySymbol}${formatCurrencyNumber(value)}`;
  return formattedValue;
};

import useLocalStorage, { saveLocalStorage } from '@/common/utils/localStorage';
// Elementos
const state = {
  user: null,
  history: {},
  server: null,
  socket: null,
  positions: {},
  splitter: NaN,
  firstView: true,
  darkTheme: useLocalStorage('theme')[0],
};

// Llamar elementos de este módulo a otros componentes
const getters = {
  user: (state) => state.user,
  socket: (state) => state.socket,
  server: (state) => state.server,
  history: (state) => state.history,
  splitter: (state) => state.splitter,
  positions: (state) => state.positions,
  firstView: (state) => state.firstView,
  darkTheme: (state) => state.darkTheme,
};

// Comportamiento
const mutations = {
  // Actualizar los datos de inicio de sesión del usuario
  updateUser(state, payload) {
    state.user = payload;
  },
  // Actualizar thema del programa
  updateDarkTheme(state, payload) {
    state.darkTheme = payload;
    saveLocalStorage('theme', payload);
  },
  // Actualizar datos del servidor
  updateServer(state, payload) {
    state.server = payload;
  },
  // Actualizar datos de socket
  updateSocket(state, payload) {
    state.socket = payload;
  },
  // Actualizar datos del splitter panel
  updateSplitter(state, payload) {
    state.splitter = payload;
  },
  // Actualizar primera vista al cargar el mapa con las unidades
  updateFirstView(state, payload) {
    state.firstView = payload;
  },
  // Actualizar datos de posiciones de dispositivos
  updatePositions(state, payload) {
    const liveRoutes = state.user ? state.user.attributes.mapLiveRoutes || state.server.attributes.mapLiveRoutes || 'none' : 'none';
    const liveRoutesLimit = 10;
    payload.forEach((position) => {
      state.positions[position.deviceId] = position;
      if (liveRoutes !== 'none') {
        const route = state.history[position.deviceId] || [];
        const last = route.at(-1);
        if (!last || (last[0] !== position.longitude && last[1] !== position.latitude)) {
          state.history[position.deviceId] = [...route.slice(1 - liveRoutesLimit), [position.longitude, position.latitude]];
        }
      } else {
        state.history = {};
      }
    });
  },
};

export default { state, getters, mutations };

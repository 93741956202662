import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import quasarUserOptions from './quasar-user-options';
import traccar from './common/connector';
// Url Base del socket
const urlbase = 'https://rastreobusmen.geovoy.com';
// Crear proyecto
createApp(App).use(Quasar, quasarUserOptions).use(store).use(router)
  .use(traccar, urlbase)
  .mount('#app');
